.usersContainer .analysisTop{
border: none;
padding-bottom: 0;
background-color: white;
border-radius: 17px 0 0 0;
}
.usersContainer .export{
    background:  #F73782;
    min-width: 125px;
}
.export:hover{
    background:  #e22671;

}
.navigateTop{
    display: flex;
    align-items: center;
}
.navigateTop p{
    margin: 0;
}
.navigateTop img{
margin:  0 8px;
}
.screenTitle{
    color: var(--gray-900, #101828);
text-align: start;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px;
}
.bc1{
    color: var(--gray-600, #475467);
   
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}
.bc2{
    border-radius: 6px;
    background: var(--gray-50, #F9FAFB);
    color: var(--gray-700, #344054);
padding: 4px 8px;
font-size: 14px;
font-style: normal;
font-weight: 600;
}
.tableTitle{
margin-bottom: 0;
color: var(--gray-700, #344054);
/* 24/Semibold */
font-size: 24px;
font-style: normal;
font-weight: 600;

}
.flexAlign{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleBtn svg{
    margin-right: 8px;
}
.titleBtn{
    color: var(--gray-700, #344054);
padding: 10px 16px;
font-size: 14px;
font-style: normal;
font-weight: 600;
border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.tableTop{
    padding: 24px 32px;
    margin-top: 32px;
    background: var(--gray-50, #F9FAFB);}

    .tableUsers table{
 
        width: 100%;
    }
  .tableUsers{
    max-width: 75vw;
    overflow: scroll;
  }
  .singleUserTableContainer .tableUsers{
    max-width: 70vw;

  }
    .tableUsers th{
        padding: 12px 24px;
        color: var(--gray-600, #475467);
/* 14/Semibold */
white-space: nowrap;
font-size: 14px;
font-style: normal;
font-weight: 600;
text-align: start;
    }
    .tableUsers td{
        height: 72px;
        padding: 24px ;
        white-space: nowrap;
        color: var(--gray-600, #475467);
font-size: 14px;
text-align: start;

font-style: normal;
font-weight: 500;
border-top: 1px solid #EAECF0;

    }
    .tableUsers {
        border-bottom: 1px solid #EAECF0;
        margin-bottom: 72px;

    }
     .tableImg{
width: 32px;
height: 32px;
margin-right:12px ;
border-radius: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
    }
    .tableName {
        color: #101828 !important;

    }
    .tableName span{
        color: var(--gray-600, #475467) !important;

    }
    .tableName{
        display: flex;
        align-items: center;
    }
    .usersContainer .analysisContainer{
        padding-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       
    }
   .tableBottomPagination{ 
       border-radius: 0px 0px 0px 40px;
    background: var(--gray-50, #F9FAFB);
padding: 24px 32px;
padding-bottom: 38px;
   }
   .totalTable{
    
    color: var(--gray-500, #667085);
    text-align: center;
    /* 20/Semibold */
margin-bottom: 24px;    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
   }
  
   .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root{
    border-radius: 4px !important;
    background-color: var(--primary-4, #EEF4FF) !important;
    color: var(--neutrals-3, #858D9B) !important;

   }
   .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: rgba(247, 55, 130, 0.8) !important;
    color: white !important;
    border-radius: 4px !important;
}
.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    color:   rgba(53, 64, 82, 1) !important
}
.pageNum{
    color: var(--neutrals-3, #858D9B);
font-size: 16px;
font-style: normal;
font-weight: 400;
}
.goTo{
    display: flex;
    align-items: center;
}
.goTo p{
margin-bottom: 0;
color: var(--neutrals-3, #858D9B);

font-size: 14px;
font-style: normal;
font-weight: 400;
}
.goTo input{
    border-radius: 4px;
border: 1px solid var(--neutrals-5, #D8D8D8);
width: 32px;
height: 32px;
margin: 0 16px;
background-color: transparent;
}
.goTo button{
    background-color: transparent;
    color: var(--secondary-500, #C80853);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    border: none;
}
.filtersMore{
    margin-top: 1em;
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center
}
@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}
.filtersLess{
display: none;
}

.filtersContainer{
    display: flex;
    
    width: 100%;
    text-align: start;
}
.flex-2{
    flex: 2;
}
.flex-1{
    flex: 1;
    margin-left: 8px;
}
.filtersContainer label{
display: block;
color: var(--gray-700, #344054);

font-size: 14px;
font-style: normal;
margin-bottom: 6px;
font-weight: 500;
}
.filtersContainer input,select{
    padding: 10px 14px;
    color: var(--gray-500, #667085);
width: 100%;

font-size: 16px;
font-style: normal;
font-weight: 400;
    border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.filtersContainer select{
    color: var(--gray-900, #101828);
   
}
.select-container {
    position: relative;
  }
  
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .select-arrow {
    position: absolute;
    top: 32%;
    right: 7px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
  .select-arrow svg{
    color: rgba(102, 112, 133, 1);
 
  }
   input[type="date"]::-webkit-inner-spin-button,
 input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  
}
.date-container{
    position: relative;
}
.date-container input{
    padding-left: 36px;
}
.date-container img{
    position: absolute;
    bottom: 13px;
    left: 10px;
}
.alignEnd{
display: flex;
align-items: end;
justify-content: end;
}
.apply{
    border-radius: 8px;
background: var(--secondary-400, #F50A66);
color: var(--gray-25, #FCFCFD);
padding: 10px 16px;
border: none;
font-size: 14px;
margin-left: 37px;
font-style: normal;
font-weight: 600;
white-space: nowrap;

}
.clear{
color: var(--neutrals-3, #858D9B);
border: none;
padding: 10px 16px;
white-space: nowrap;
background-color: transparent;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
.profile-container{
    padding: 24px 32px;
    background: var(--gray-50, #F9FAFB);
}
.profileId{
    padding: 33px 48px;
    text-align: end;
    background: var(--secondary-25, #FEE9F1);

}
.profileId p{
    color: var(--secondary-400, #F50A66);
margin-bottom: 0;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.profileData{
    background-color: white;
    padding: 24px;
    text-align: start;
    position: relative;
    padding-top: 68px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
.profileImg{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top:-45px ;

}
.userName{
    color: var(--gray-700, #344054);
  margin-top: 32px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 12px;
}
.joinDate{
    color: var(--gray-400, #98A2B3);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.profileContact{
    display: flex;
    margin: 32px 0;
    justify-content: space-between;
}
.profileContact p{
    color: var(--gray-600, #475467);
    font-size: 16px;
        font-weight: 500;
        margin: 0 8px;
}
.profileContact span{
    color: var(--gray-600, #667085) !important;

}
.profileContact div{
    display: flex;
    align-items: center;
}
.profileContact img, .profileContact svg{
    border-radius: 2px;
    background: var(--secondary-25, #FEE9F1);
 padding: 6px;
}
.profileContact svg{
    width: 28px;
    height: 28px;
}
.usrAddress{
    color: #475467;
font-size: 14px;
font-weight: 500;
line-height: 20px;
}
.usrAddress span{font-size: 16px;
font-style: normal;
font-weight: 600;
margin-bottom: 32px;}
.calss{
    color:  #344054;

font-size: 18px;
font-weight: 600;
line-height: 28px
}
.calssDiv p{
    color: #743900;
text-align: center;
display: inline-block;
font-size: 14px;
font-weight: 500;
padding: 4px 12px 4px 10px;
margin-right:32px ;
line-height: 20px; 
border-radius: 16px;
background: var(--primary-50, #FFDCB9);
mix-blend-mode: multiply;
}
.singleUserTableContainer{
    background: var(--gray-50, #F9FAFB);

    padding:0 32px;
}
.singleUserContainer .analysisContainer{
   background: var(--gray-50, #F9FAFB);
 /* background: white*/
 padding-top: 0;

}
.singleUserContainer .welcomeDiv{
    margin-top: 0;
    padding-top: 48px;
}
.singleUserContainer .analysisTop{
    padding-top: 24px;
}
.singleUserTableContainer .tableTop{
    background-color: white;
    margin-top: 0;
}
.singleUserTableContainer .tableUsers{
    background-color: white;
 
}
.tableChoice{
    display: flex;
    justify-content: space-between;
    background-color: white;
}
.tableChoice div{
    border-radius: 12px;

    flex: 1;
    padding: 11px 27px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
.tableChoice .activeChoice{
    border-radius: 12px;

}
.tableChoice img{
    float: left;
    margin-right: 8px;
}
.tableChoice p{
    color:#344054;
font-size: 14px;
text-align: start;
font-weight: 500;
line-height: 20px;
}
.tableCount{
    position: relative;
    top: 6px;
    margin-bottom: 0 !important;
    color: #C80853 !important;
font-size: 18px !important;
font-weight: 600 !important;
line-height: 28px !important;
}
.activeChoice{
    border-radius: 12px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--gray-50, #F9FAFB);
box-shadow: none !important;
}
.tableChoice{
    margin-bottom: 54px;
}
.choiceTitle{
    color: var(--gray-800, #1D2939);
padding-bottom: 20px;
border-bottom: 1px solid #EAECF0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
}
.titleAdd{
    border-radius: 8px;
    color:  #FCFCFD;
text-align: center;
padding: 12px;
border: none;
margin-right: 20px;
font-size: 14px;
font-weight: 600;
line-height: 20px;
background: #F50A66;
/* Shadow/xs */
width: 14.5vw;

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.titleExp{
    border-radius: 8px;
    color:  #F50A66;
text-align: center;
padding: 12px;
width: 14.5vw;
border: 1px solid #F50A66;

font-size: 14px;
font-weight: 600;
line-height: 20px;
background: white;
/* Shadow/xs */
}
.srchIcn{
    position: relative;
}
.srchIcn svg{
    position: absolute;
    top: 13px;
    left: 12px;
}
.srchIcn input{
    padding-left: 38px;
}

 .modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.modalOverlay {
    background: rgba(0, 0, 0, 0.40);}

.modal-content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 5%);
    line-height: 1.4;
    background-color: white !important;
    padding: 32px !important;
    border-radius: 12px !important;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10) !important;
    z-index: 12;

   display: unset;
width: 500px;
height: 571px;
}
.addBody p{
    color:  #1D2939;
text-align: start;
font-size: 36px;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
margin-bottom: 48px;
}
.addBody label{
    color: #344054;
text-align: start;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
    line-height: 20px;
}
.dateDiv{
    position: relative;
}
.dateDiv img{
    position: absolute;
    right: 12px;
    top: 14px;
}
.addBody input, .addBody select{
    margin-bottom: 24px;
    padding: 14px 14px;
    color: #667085;
width: 100%;

font-size: 16px;
font-style: normal;
font-weight: 400;
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addBody .select-arrow{
    top: 34% !important;
}
.modalBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.confirm{
    border-radius: 8px;
    background:  #F50A66;
    /* Shadow/xs */
    border: none;
    color:  #FFF;
    padding: 12px;
    width: 45%;

font-size: 16px;
font-weight: 600;
line-height: 24px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.cancel{
    border-radius: 8px;
    color:  #F50A66;
text-align: center;
padding: 12px;
width: 45%;
border: 1px solid #F50A66;

font-size: 14px;
font-weight: 600;
line-height: 20px;
background: white;
}
.editContainer{
    padding: 20px;
    background-color: white;
    margin: 32px;
    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.editTitle{
    color: #1D2939;
font-size: 36px;
margin-bottom: 54px;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
text-align: start;
}
.editRow{
    display: flex;
}
.editRow div{
    flex: 1;
}
.editRow label{
    color: var(--neutrals-2, #354052);
display: block;
margin-bottom: 8px;
    font-size: 14px;
text-align: start;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.42px;
}
.editRow input{
    padding: 10px 14px;
    color: var(--gray-500, #667085);
width: 100%;
margin-bottom: 50px !important;
font-size: 16px;
font-style: normal;
font-weight: 400;
    border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.editBtns button{
    width: 175px;
}
.editBtns .confirm{
    margin-right: 32px;
}
.editBtns{
    text-align: end;
    margin-top: 45px;
}
.multi-selectInput{
    padding: 9px 14px;
  white-space: nowrap;
width: 100%;
overflow: auto;
    border-radius: 8px 8px 0 0;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);
text-align: start;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);   
}
.multi-selectDef{
    color: var(--gray-500, #667085);
    font-size: 16px;
font-style: normal;
font-weight: 400;
margin-bottom: 0;
}
.mutli-select{
    position: relative;
margin-bottom: 50px !important;}

.mutli-select .select-container{
    cursor: pointer;
}
.mutli-select .select-arrow{
    top: 50% !important;
}
.mutli-selectClosed .multi-selectInput{
border-radius: 8px !important;
display: flex;
overflow: scroll;
}
 .editRow .multi-selectInput{
     max-width: 20.5vw;
 }
.chocies{
    white-space: nowrap;
    padding: 2px 9px !important;
    flex: none !important;
}
.chocies p{
    margin-bottom: 0;
}
.mutli-selectClosed .select-arrow{
transform: scale(-1);
top: 30% !important;
}
.select-dropdown{
    width: 100%;
    position: absolute;
overflow: auto;
z-index: 10;
max-height: 320px;
    border-radius: 0px 0px 8px 8px;
border: 1px solid var(--gray-200, #EAECF0);
background: var(--base-white, #FFF);
padding: 12px 8px 12px 8px;
box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.mutli-selectClosed .select-dropdown{
display: none;}
.mutli-select svg{
    left: auto !important;
    right: 8px;
}
.containCheck{
    width: fit-content;
}
.containCheck input[type="checkbox"] {
    cursor: pointer;
    border-radius: 3px !important;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
  color: currentColor;
  height: 16px;
  width: 16px;
 padding: 0 !important;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  display: inline-flex;
  place-content: center;
  }
  
  .containCheck input[type="checkbox"]::before {
  content:"";
    width: 100%;
    height: 100%;
    border-radius: 3px;

    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em  #FCBCD5;
 }
 .containCheck svg{
     position: absolute;
display: none;
top: 1.5px !important;
     left: -2.3px !important;
 padding-bottom:  1.5px;
     pointer-events: none; 
 }
 .containCheck div{
     width: fit-content;
     position: relative;
     margin-left: auto;
 }
 .containCheck{
     position: relative;
 }
 .underline{
     text-decoration: underline;
 }
 input[type="checkbox"]:checked + svg{
     display: initial;
 }
 .containCheck input[type="checkbox"]{
     margin-bottom: 0 !important;
 }
  .containCheck input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  .containCheck input[type="checkbox"]:checked{
  border: 1px solid rgba(111, 5, 46, 1);}
  .labelCheck  {
      position: relative;
margin-left: 0.3em !important;
  }
  .spaceBtn{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .select-dropdown label,   .select-dropdown  input{
margin-bottom: 0 !important;
  }
  .spaceBtn{
      margin-top: 10px;
      padding: 10px 8px;
    
  }
  .checked{
    border-radius: 6px;
 
    background: var(--secondary-25, #FEE9F1);  }
  
   
    .invalid, .editRow .invalid{
        margin-bottom: 8px !important;
    }