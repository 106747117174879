 .termsContainer{
     padding: 24px 0;
 }
 .termsTop{
    background:  #FEE9F1;
    padding: 90px 0;
 }
 .termsTitle{
    color:  #344054;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    line-height: 72px; /* 120% */
    letter-spacing: -1.2px;
    margin-bottom: 0;
 }
 .termsDate{
    color: #667085;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0;

 }
 .termsBody{
     padding: 10px 32px;
 }
 .termsCard{
padding: 32px;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
 }
 .cardTop{
    border-radius: 8px;
    padding: 16px;
    border: 1px solid  #C80853;
    background:  #F9F5FF;
    margin-bottom: 24px;
 }
 .cardTop p{color:  #C80853;
margin-bottom: 0;
font-size: 18px;
font-weight: 600;
line-height: 28px; }
.decrip{
    color: #101828;
margin-bottom: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;

    line-height: 28px; /* 155.556% */
}
.termsCard{
    text-align: start;
}
.dexripTxt{
    color:  #475467;
padding-bottom: 20px;
border-bottom: 1px solid #EAECF0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 20px; /* 142.857% */
}
.quillCard{
    border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
padding: 32px;
}
.editTermsContainer{
    padding: 32px 12px;
}
.ql-toolbar.ql-snow{
    border-radius:8px 8px 0 0 ;
    border: 1.111px solid  #F2F2F2 !important;

}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border-radius:0 0 8px 8px ;
    border: 1.111px solid  #F2F2F2;

}
.ql-editor{
    padding: 70px !important;
    max-height: 460px;
    overflow: scroll;
}
.ql-toolbar.ql-snow{
    padding: 17px !important;
    text-align: start;
}
.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker{
    bottom: 2px;
}

.editTermsContainer .modalBtns{
    margin-top: 64px;
justify-content: end;}
.editTermsContainer .modalBtns button{
    width: 175px;

}
.editTermsContainer .cancel{
    margin-left: 32px;
}
.addPost .quill, .editPost  .quill{
    display: flex;
    flex-direction: column-reverse;
}
.addPost .ql-editor, .editPost .ql-editor{
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;

padding: 12px 14px !important;
height: 128px;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addPostCs textarea{
    border-radius: 8px;
    border: 1px solid  #D0D5DD;
    background:  #FFF;
    width: 100%;
    padding: 12px 14px !important;
    height: 128px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addPost .ql-toolbar.ql-snow, .editPost .ql-toolbar.ql-snow{
    border: none !important;
    padding: 0 !important;
    margin-bottom: 32px;
    margin-top: 37px;
}
.addPost .ql-snow .ql-picker.ql-size, .editPost .ql-snow .ql-picker.ql-size{ 
    margin-right: 12px;
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background: #FFF;
padding:9px 14px;
height: unset;
width: 240px;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.addPost .ql-toolbar.ql-snow .ql-formats, .editPost .ql-toolbar.ql-snow .ql-formats{
    display: flex;
    align-items: center;
}
 .charsDiv{
    position: relative;
}
.charsDiv p{
    color:  #101828;
/* Text md/Normal */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}
 .addPost .chars, .editPost .chars, .addPostCs .chars{
    color:  #475467 ;
top: 135px;
position: absolute;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.editPost .chars{
    top: 167px;
 
}
.addPostCs .chars{
    position: static;
    margin-bottom: 32px;
}
.addPostCs input[type="text"]{
    height: 128px;
    margin-bottom: 6px;
}
.addPostCs .modalBtns, .addPost .modalBtns{
width: 50%;
text-align: start;
}
.editPost .chars{
    text-align: start;
    margin-bottom: 50px !important;

    position: static;}

   

    .editPost textarea{
        margin-bottom: 6px;
        border-radius: 8px;
        border: 1px solid  #D0D5DD;
        background:  #FFF;
        width: 100%;
        padding: 12px 14px !important;
        height: 128px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }