.featImg{
    background-size: cover;
    background-repeat: no-repeat;
    width: 64px;
height: 42px;

background-position: center;
}
.categImg{
    width:47px;
     height:47px;
     background-size: cover;
     background-repeat: no-repeat;
     border-radius: 100%;
     background-position: center;

}
.addCateg #uploadImg{
margin-bottom: 24px;
}
.addCateg #uploadImg p{
    margin-bottom: 0 ;
}
.addCateg .modalBtns{
    width: 50%;
}
table input{
    padding: 9px 14px;
  width: 100%;
  color:  #98A2B3;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
border-radius: 8px;

  border: 1px solid  #D0D5DD;
  background:  #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);   
}
.categoryTable{
    padding: 0 32px;
    border: none !important;
}
.categoryTable table{
    border-bottom: 1px solid #eaecfe
}
