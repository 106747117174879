.dashboardContainer{
    display: flex;
}
.sideNav{
    min-width: 260px !important;

    background-color: #DB095B;
    padding-top: 32px ;
    background: linear-gradient(180deg, #9C0641 0%, #DB095B 100%);

}
.dashobard{
    width: 100%;
    background: linear-gradient(45deg, #43031C 0%, #C80853 100%);
}
.analysisContainer{
    background-color: white;
    border-radius: 40px 0px 0px 40px;
    width: 100%;
    padding:26px 0 ;
    height: 100%;
}
.navLogo{
height: 30px;
width: 100%;
background-size: contain;
background-repeat: no-repeat;
background-position: center;
}
.navPp{
    height: 100px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%; 
    margin: auto;
    margin-top: 48px; 
    margin-bottom: 8px;
}
.usrName{
    color:  #FCFCFD;
    margin-bottom: 8px;

    font-size: 20px;
    font-weight: 600;
}
.usrPosition{
    color: #F9FAFB;

    font-size: 14px;
    font-weight: 500;
}
.sideNav ul{
    list-style: none;
    padding-left: 0;
    border-bottom: 1px solid white;
    padding-bottom: 32px;
}
.sideNav a{
    text-decoration: none;
    color: #FFF;
display: block;
font-family: 'Inter' !important;
font-size: 16px;
font-style: normal;
font-weight: 600;
padding: 16px;
display: flex;
align-items: center;
line-height: 24px; 
border-radius: 6px;
transition: 0.5s;

}
.sideNav li{
    margin-top: 4px;
    text-align: start;
    
}
.sideNav .active{
    background: linear-gradient(0deg, rgba(134, 11, 59, 0.60) 0%, rgba(134, 11, 59, 0.60) 100%), #9C0641;

}

.sideNav li svg, .sideNav li img{
    margin-right: 24px;
}
.navBottom{
    display: flex;
    text-align: start;
    justify-content: space-between;
}
 .bottomName{
    font-family: 'Inter' !important;
font-size: 14px;
font-style: normal;
color: #FCFCFD;
margin-bottom: 0;
font-weight: 600;
line-height: 20px;
}
 .bottomEmail{
    color: #FCFCFD;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;

}
.usrLogo{
    width: 48px;
height: 48px;
border-radius: 100%;
margin-right: 12px;
background-size: cover;
background-repeat: no-repeat;
}
.alignCenter{
    display: flex;
    align-items: center;
}
.logoPlain{
    width: 91.998px;
height: 69.986px;
background-size: contain;
background-repeat: no-repeat;
flex:1;
background-position: unset;
}
.analysisTop{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D0D5DD;
    padding:  0 32px;
    padding-bottom: 10px;
}
.srchDiv{
 position: relative;
 width: 100%;
 flex: 2;
}
.srchDiv svg{
    position: absolute;
    left: 25px;
    top: 11px;
}
.srchDiv input{
    border-radius: 8px;
    border: 1px solid  #D0D5DD;
    background:  #F2F4F7;
    /* Shadow/xs */
    padding: 9px 50px;
    width: 100%;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.flexBetween{
display: flex;
justify-content: space-between;
align-items: center;
}
.welcomeDiv{
    margin-top:48px;
    padding: 0 32px;
    padding-bottom: 32px;
    background-color: white;
}
.welcome{
    font-size: 18px;
    font-weight: 600;
    color: #475467;
    margin-bottom: 8px;
    text-align: start;
   
}
.welcomeTxt{
    font-size: 12px;
    font-weight: 500;
    color: #98A2B3;
    text-align: start;

}
.export{
    padding: 8px 10px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: none;
    background:  #9C0641;
    font-family: 'Inter' !important;
font-size: 16px;
font-weight: 600;
color: white;
transition: 0.2s;
}
.export svg{
    margin-right:8px ;
}
.export:hover{
    background:  #e22671;

}
.dashType button{
    background-color: transparent;
    border: none;
    color:  #667085;
/* Text md/Semibold */
font-family: 'Inter' !important;
font-size: 16px;
font-style: normal;
font-weight: 600;
padding: 10px 14px;

}
.dashType .activeType{
    background-color: white;
    color:#344054;
    border-radius: 6px;
}
.dashType{
    background-color: #F9FAFB;
    padding: 8px;
    justify-content: space-between;
    border-radius: 6px;
display: flex;

}
.dashType button:hover{

    background-color: white;
    color:#344054;
    border-radius: 6px;  
}
.dashNavigate{
    margin-top: 48px;
    display: flex;
    padding: 0 32px;
    align-items: center;
    justify-content: space-between;
}
.dataStatus{
border: 1px solid #D0D5DD;
border-radius: 6px;
padding: 1px;
}
.dataStatus button{
    border: none;
    font-family: 'Inter' !important;
font-size: 14px;
padding: 10px 16px;

font-weight: 600;
    color:#344054;
    background-color: white;
}
.activeStatus{
    color:#1D2939 ;
    background-color: #F9FAFB !important;

}
.dataStatus button:hover{
    color:#1D2939;
    background-color: #F9FAFB;
}
.midStatus{
border:1px solid #D0D5DD !important;
border-style: none solid none solid !important;
}
.cardShadow{
    border-radius: 12px;
border: 1px solid  #EAECF0;
background: white;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.cardShadow select{
    width: 101px;
}
.chartsDiv{
    margin-top: 42px;
}
.priceCount{
    display: flex;
    align-items: center;
}
.cashLogo{
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
border: 8px solid #FCBCD5;
background:  #FA90B9;
width: 60px;
height: 60px;
margin-right: 14px;

}
.totalTitle{
    color: #475467;
 text-align: start;
    font-size: 12px;
margin-bottom: 4px;
    font-weight: 500;
    line-height: 18px; 
}
.totalNum{
    margin-bottom: 0;
    color: #101828;
    text-align: start;

font-size: 20px;
font-weight: 600;
line-height: 30px;
}
.upPercentage{
    color: #000;
text-align: center;
font-family: 'Inter' !important;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.upPercentage span{
    border-radius: 16px;
background: var(--success-50, #ECFDF3);
color:#027A48 !important;
margin-right: 8px;
font-size: 14px !important;
padding: 2px 10px 2px 8px;
display: inline-flex;
align-items: center;
width: fit-content;
}
.graphTitle{
    text-align: start;
display: flex;
align-items: center;
    color: #344054;
   margin-bottom: 1.8px;
    font-size: 14.401px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.602px;
}
.graphTotal{
    color:#0B1354;
text-align: start;
font-size: 29.431px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -1.104px;
}
.chartTitle select{
    background-color: transparent;
    color: #667085 !important;
    border-radius: 5.4px;
    border: 0.9px solid #D0D5DD;
font-size: 12.601px;
font-style: normal;
font-weight: 500;
line-height: 18.001px;
padding:5px 10px;
cursor: pointer;
}
.chartTitle{
    border-bottom: 2px solid #ECECEC;
    margin-bottom: 1em;
}
.graphTitle span{
    border-radius: 16px;
    background: var(--success-50, #ECFDF3);
    color:#027A48 !important;
    margin-left: 8px;
    font-size: 14px !important;
    padding: 2px 10px 2px 8px;
    display: inline-flex;
    align-items: center;
    width: fit-content;  
}
.upPercentage{
    color: #000;
font-family: 'Inter' !important;
font-size: 11px;
text-align: start;
margin-top: 16px;
font-weight: 500;
display: flex;
margin-bottom: 0;
align-items: center;
}
.recharts-surface{
    width:100%;
}

.recharts-legend-wrapper{
    display: none;
}
.lineColors p{
  align-items: center;
    display: inline-flex;
    margin-right: 25px;
    color:  #4F4F4F;
font-family: 'Inter' !important;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.lineColors svg{
    margin-right: 5px;   
}
.lineColors{
    text-align: start;
    position: relative;
    left: 60px;

}
.pieTop{
    border-bottom: 1px solid #d1d5db;
    text-align: start;
}
.pieTitle{
    color:  #101828;
    /* Text lg/Semibold */
    font-family: 'Inter' !important;
    font-size: 18px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
.pieTxt{
    color:  #475467;
    /* Text sm/Normal */
    font-family: 'Inter' !important;
    font-size: 14px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.pieBottom{
    color: #475467;
    font-family: 'Inter' !important;
    font-size: 13.653px;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 0;
    line-height: 19.504px;
}
.pieCol{
    height: 100%;
}
.ratings{
    color: var(--gray-600, #475467);
text-align: center;
font-size: 30.03px;
font-style: normal;
font-weight: 600;
margin:  25px 0;
}
.RateBtn{
    border-radius: 12.012px;
background: var(--secondary-500, #C80853);
color: var(--gray-25, #FCFCFD);
text-align: center;
font-size: 18.018px;
font-style: normal;
border: none;
font-weight: 600;
padding: 12px 0;
width: 100%;
}
.paddingRatting{
    padding:32px 16px !important;
}
.removeTxt{
    margin-bottom: 0;
}
.dashboardContainer2 .navLogo,.dashboardContainer2 .navPp,.dashboardContainer2 .usrName,.dashboardContainer2 .usrPosition,.dashboardContainer2 .removeTxt,.dashboardContainer2 .bottomName,.dashboardContainer2 .bottomEmail,.dashboardContainer2 .navBottom svg{
display: none;
}

.dashboardContainer2 .sideNav a{
 width: min-content;
 padding: 12.5px;
}
.dashboardContainer2 .sideNav img{
    margin-right: 12px;
}
.dashboardContainer2 .sideNav svg{
    margin: 0;
}
.dashboardContainer2 .sideNav ul{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
 .navBranch{
    display: none;
    flex: 1
}
.dashboardContainer2 .navBranch{
    text-align: start;
}

.dashboardContainer2 .sideNav{
display: flex;

}
.navBranch p{
    color: var(--base-white, #FFF);

font-size: 16px;
font-style: normal;
font-weight: 500;

}

.dashboardContainer2 .navBranch svg{
    margin-right: 12px;
}
.dashboardContainer2 .sideNav li{
    margin-top: 0;
    margin-bottom: 12px;
}
.dashboardContainer2 .navBranch a{
width: 100%;
}

.dashboardContainer2 .sideNav{
  min-width: 25vw !important;
}
.walletAlign{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.walletBalance{
    border-radius: 16px;
background: var(--gray-25, #FCFCFD);
mix-blend-mode: lighten;
color: #a70545 !important;
padding: 0 8px;
margin-bottom: 0;
}

.removeActive{
    background: transparent !important;
}
.sideNav a:hover{
    background: linear-gradient(0deg, rgba(134, 11, 59, 0.60) 0%, rgba(134, 11, 59, 0.60) 100%), #9C0641 !important;

}

.poweredBy{
 
    border-radius: 0px 0px 0px 40px;
    background: var(--gray-50, #F9FAFB);
 
}
.poweredBy #cc{
    text-align: end;
    padding: 0 32px;
}
.powered{
    background-color:  #F9FAFB;
    padding: 10px;
display: flex;
justify-content: space-between;
}
.powered #cc{
    margin-bottom: 0;
    display: flex;
  
    
}
.powered span{
    margin-left: 5px;
    color: #FF7E00;
 
}
.dashboardContainer2 #cc a{
  width: auto !important;
  padding: 0 !important;
  margin-left: 5px;
}
.dashboardContainer2  #cc a:hover{
    background: transparent !important;
}