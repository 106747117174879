@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Cairo:wght@200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


p,input, label, input::placeholder,button,a,span{
  font-family: 'Montserrat' !important;
  font-style: normal;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a{
  text-decoration: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.maxHeight {
  max-height: 60vh;
  overflow: auto;
  padding: 0 32px;
}
.maxHeightModal .modal-content{
  top: 50% !important;
  padding: 32px 0 !important;
  transform: translate(-50%, -50%) !important;
}
.maxHeightModal .modalTitle {
  padding: 0 32px;
}
.dashboardContainer .maxHeightModal .modal-content{
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.maxHeightModal .modalOverlay{
height: 100vh;
}
.maxHeightModal{
  height: -webkit-fill-available;
 
}
.dateDiv img{
  pointer-events: none;
}
.invalid, .editContainer .select-container .invalid{ 
  margin-bottom: 8px !important;
}

.addBody .invalid + .select-arrow{
  top: 44% !important;

}


.editContainer{
  overflow: hidden;
}
.chocies{

}
.editContainer .select-container .invalid + .select-arrow{
  top: 44% !important;
}
 .editAgent .editRow .invalid{
margin-bottom: 8px !important;
}
.powered a:hover{
  background-color:  transparent !important;
}
.powered a{
  padding: 0 !important;
  margin-left: 5px;
}