.promotionContainer .tableTop{
margin-top: 8px !important;
}

.promoContainer .select-arrow{
    top: 33%;
}
.editQuan{
    text-align: start;
    margin-bottom: 28px;
    color:  #354052;
font-size: 30px;
font-weight: 600;
line-height: 38px;
}
.AddOffer .supervisor label{
margin-left: 10.5px !important;
}
.AddOffer .supervisor{
    width: 85% !important;
}
.AddOffer input[type="checkbox"] {
    border: 1px solid rgba(111, 5, 46, 1) !important;
}
