.countryFlag{
    width: 100%;
    height: 100%;
     min-width: 111px;
     background-repeat: no-repeat;
     background-size: cover;
}
.CountryTable td{
padding: 14px 24px;
}
.centerData{
    text-align: center !important;
}

#uploadedImg{
    position: relative;
    width: fit-content;
    margin: auto;
  }
  #uploadedImg button{
  position: absolute;
  right: -13px;
  top: -10px;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.14);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  }
  #uploadIcn{
    background: #f2f4f753;
    border-radius: 100%;
    display: inline-block;
    padding: 8px 7px;
    margin-bottom: 0.5em;
  }
  #uploadIcn div{
    background-color:  #F2F4F7;
    padding: 3px 7px;
    border-radius: 100%;
  }
  #uploadImg p{
    color:  #475467;
  text-align: center;
  /* 14/Regular */
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0 !important;
  }
  #uploadImg span{
    color:  #F50A66;
    font-size: 14px;

    cursor: pointer;
  font-style: normal;
  font-weight: 600;
  
  }
  #uploadImg input{
    position: absolute;
    z-index: -1;
    height: 0;
    width: 0;
  }

#uploadImg{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  text-align: center;
      background: #FFFFFF;
  /* Gray/300 */
  
  padding: 1em;
  border: 1px solid #D0D5DD;
  /* Shadow/xs */
  
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%; 
  }
  #uploadImg img{
    max-height: 25vh;
  }
  .countryImg{
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
      height: 135px;
      margin-top: 34px;
  }
  .addCity .modalBtns, .addArea .modalBtns{
    justify-content: start;
  }
  .addCity{
    transform: translate(-50%, 35%);

  }
  .addCity button, .addArea button{
    width: 175px;
    margin-right: 32px;
  }

