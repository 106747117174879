.alignCenter{
    display: flex;
    justify-content: end;
    align-items: end;

}
.chatContainer{
    display: flex;
    background: #F9FAFB;
    padding-bottom: 48px;
}
.chatList{
flex:1;
background-color: white;
padding: 24px 0;
text-align: start;

}
.overflowList{
    height: 960px;
    overflow: auto;
}
.chat{
    flex:2;
}
.chatListTitle{
    display: inline-block;
    color: #101828;
    font-size: 18px;
    margin-bottom: 0;

    font-weight: 600;
    line-height: 28px; 
}
.chatCount{
    border-radius: 16px;
background: #FCBCD5;
mix-blend-mode: multiply;
color:  #6F052E;
display: inline-block;
margin-left: 8px;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
width: 33px;
height: 22px;
margin-bottom: 0;
text-align: center;
}
.chatListTopper{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 16px;
}
.chatList .srchDiv{
padding: 0 16px;}
.chatList .srchDiv input{
background-color: white ;
padding:9px 14px;
margin-bottom: 12px;
padding-left: 36px;
}
.chatList .srchDiv svg{
    left: 30px;
}
.flexBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.alignTxt{
    display: flex;
    align-items: center;    
}
.msgDot{
    height: 8px;
    width: 8px;
    background-color: #C80853;
    border-radius: 100%;
    margin-right: 12px;
}
.chatUsrName{
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
}
.chatDiv{
    padding: 16px ;
    cursor: pointer;
    border-bottom: 1px solid  #EAECF0;
}
.chatDiv:hover{
    background: #F9FAFB;

}
.chatList{
    border-right:  1px solid  #EAECF0;

}
.activeChatDiv{
    background: #F9FAFB;
}
.chatUsrPhone{
    color:  #475467;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    line-height: 20px; /* 142.857% */
}
.lastMsg{
    color:  #475467;
margin-bottom: 0;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.timeAgo{
    margin-bottom: 0;

    color:  #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.chat .flexBetween{
    background-color: white;
    padding: 20px 24px;
    border-bottom: 1px solid  #EAECF0;

}
.chatUsr{
    color:  #101828;
font-size: 18px;
margin-bottom: 0;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.block{
    color:  #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;
padding: 10px 16px;
margin-right: 12px;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.actnList{
    border-radius: 8px;
    border: 1px solid  #F50A66;
    background:#F50A66;
    color:  #FFF;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.singleTxt{
    display: flex;
    align-items:flex-start;
}
.chatData{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 6px;
}
.chatName{
    color:  #344054;
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}
.chatDate{
    color: #475467;
margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}
.chatTxt{
    padding: 10px 14px;
    border-radius: 0px 8px 8px 8px;
background:  #F2F4F7;
}
.chatScrollContainer{
    padding:0  32px;
    height: 970px;
    display: flex;
    justify-content: end;
    flex-direction: column;
}
.chatTxt p{
    color:  #101828;
text-align: start;
margin-bottom: 0;
    font-size: 16px;
    max-width: 480px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */ 
}
.singleTxtCs .chatTxt{
background-color: #9C0641;
}
.singleTxtCs .chatTxt p{
color: white;    }
.singleTxt {
margin-top: 17px;

}

.singleTxtCs .usrLogo{
margin-right: 0;
margin-left: 12px;
}
.singleTxtCs{
    justify-content: flex-end;
}
.chatScroll {
    display: flex;
flex-direction: column-reverse;
overflow: auto;
}
.chatInput{
    padding: 12px;
    padding-top: 5px;
    margin-top: 32px;
    border-radius: 8px;
border: 1px solid #D0D5DD;
background:  #FFF;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.chatInput textarea{
    width: 100%;
    height: 40px;
    resize: none; 

    border: none !important;
}
.chatInput textarea:focus{
    outline: none !important;
    resize: none; /* Disable resizing */
    overflow: auto; /* Enable scrolling if content exceeds editor height */

}
.sendTxt{
    border-radius: 8px;
border: 1px solid  #9C0641;
background: #9C0641;
padding:9px 16px;
color: #FFF;
margin-left: 8px;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.sendOptions{
    display: inline-block;
}
.sendOptions button{
    border: none;
    background-color: transparent;
}
.inputImg{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
padding: 10px;
position: absolute;
right: 0;
z-index: 12;
}
.inputImg button{
margin-bottom: 9px;
white-space: nowrap;
display: flex;
align-items: center;
}
.inputImg img{
    margin-right: 4px;
}
.sendOptions{
    position: relative;
}
.replies{
    padding: 10px;
    background-color: white;
    border-radius: 0px 0px 8px 8px;
background: #FFF;
position: absolute;
height: 500px;
overflow: scroll;
width: 248px;
right: 189px;
top: -432px;
z-index: 13;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}
.replies p{
    color:  #475467;
font-size: 14px;
font-style: normal;
cursor: pointer;
font-weight: 500;
margin-bottom: 10px;
border-bottom: 1px solid #F2F4F7;
padding-bottom: 4px;
line-height: 20px; /* 142.857% */
}
.replies p:hover{
    color:  #262a30;


}
.chatModalOverlay{
    background-color: transparent !important;
}
