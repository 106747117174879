.alignCheck{
   
}
.alignCheck .containCheck{
    margin-right: 5px;
    position: relative;
    top: 1.8px;
    display: inline-block;
}
.alignCheck .containCheck svg{
    top: 0px !important;
}
.status{
    color:  #027A48;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
border-radius: 16px;
background:  #ECFDF3;
mix-blend-mode: multiply;
padding: 3px 4px;
padding-right: 10px;
}
.statusOrange{
    color:  rgba(220, 104, 3, 1);
    background:  #FEF3F2;

}
.ordersBtns button{
    color: #344054 !important;
    border-radius: 8px;
border: 1px solid #D0D5DD;
background:  #FFF !important; 
min-width: 105px;
padding: 10px 16px;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
background-color: white;
font-size: 14px;
margin-left: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
}
.ordersBtns path{
    stroke: #344054 !important;

}
.invoiceLogo{
    width: 260px;
    height:40px;
    margin: auto;
    background-size:contain;
    background-repeat: no-repeat;
    margin-bottom: 27px;
}

.invoiceTitle{
    color:hsl(337, 92%, 50%) !important;
    font-size: 36px !important;
    font-weight: 600 !important;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    margin-bottom: 16px !important;
}
.invoiceName{
    color:  #475467 !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 32px;
    margin-bottom: 24px !important;

}
.invoiceUsrData{
    display: flex;
    align-items: start;
    
}
.invoiceUsrData p{
    display: inline-block;
    color:  #98A2B3;
    max-width: 214px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
 margin-bottom: 32px;
    margin-right: 16px;
}
.invoiceUsrData svg{
    margin-right: 16px;
  
}
.pinSvg{
    position: relative;
    top: 2px;

}
.invoiceUsrData path{
    stroke:rgba(247, 55, 130, 1)}

   .invoice .modal-content{
       border-radius: 0 !important;

    }
    .invoiceTable{
        width: 100%;
    }
    .invoiceTable th{
text-align: start;
color:  #98A2B3;
font-size: 14px;
font-weight: 600;
padding-bottom: 5px;
padding-right: 32px;
border-bottom: 1px solid rgba(152, 162, 179, 0.41);

line-height: 20px; 
    }
    .invoiceTable td{
        color: #667085;
font-size: 14px;
font-weight: 500;
text-align: start;
padding-right: 32px;
line-height: 20px;
padding-top: 22px;
padding-bottom: 5px;
border-bottom: 1px solid rgba(152, 162, 179, 0.41);
    }
    .tdTotal{
        color:  #F9639D !important;
        font-weight: 600 !important; 
    

    }
    .invoiceTotal td{
        padding-top: 15px ;
        padding-bottom: 0 !important;
        border: none ;
    }
    .alignCheck button{
        background-color: transparent;
        border: none;
        padding: 0;
    }
    .userModal .usrName{
        color: #667085;
        text-align: center;
margin-bottom: 16px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
    }
    .userModal .navPp{
        position: absolute;
        width: 174px;
        height: 174px;
        left: 50%; /* Position the left edge at the horizontal center of the parent */
        transform: translate(-50%, -140%); 

    }
    .userModal .modal-content{
     position: relative;
     transform: translate(-50%, 100%);
     padding-top: 111px !important;
        padding-bottom: 31px !important;
    }
    .userModal .usrPhone{
        color: #F73782;
text-align: center;
margin-bottom: 0 !important;

font-size: 24px;
font-weight: 600;
line-height: 32px;
    }
    .orderDet button{
        white-space: nowrap;
    }
    .orderDet .sync{
        margin-left: 16px;
        color:  #F73782;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
background-color: white;
padding: 10px 16px;
border-radius: 8px;
min-width: 94px;
border: 1px solid  #F73782;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
   
    .connectedDots{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        display: inline-flex;
  
    }
    
    .connectedDots{
        display: inline-block;
    }
    .connectedDots{
margin-right: 20px;
    }
   
  .line{
    width: 2px;
    height: 66px;
    background: #C80853;
    margin: auto;

  } 
  .dot{
    background: #C80853;
    width: 8px;
    height: 8px;
    margin: auto;
    margin: 2px 0;
    border-radius: 100%; 
  }
  .orderTimeLine{
      display: flex;
      align-items: flex-start;
  }
  .trackOrder{
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 32px;
    text-align: start;
  }
.trackOrder p{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .otId{
      margin-bottom: 32px !important;
  }
  .otTitle{
    font-weight: 600 !important;
    margin-bottom: 16px !important;

  }
  .odDate{
    font-size: 14px !important;
    margin-bottom: 24px !important;

  }
  .totalTime{
      margin-top: 32px;
  }
  .totalTime p{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 24px;  
  }

  .totalTime span{
    color: #98A2B3;

  }
.orderContainer {
    background: #F9FAFB;
    padding: 32px 0;
}
.odTable-flex p{
    color: #101828;
font-size: 16px;
font-style: normal;
font-weight: 500;
margin-bottom: 0;
line-height: 24px; 
}

.odTable-flex .srchIcn svg {
    top: 11px;
    left: 11px;
}


.odTable-flex{
    display: flex;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(234, 236, 240, 1);
}
.odTable{
    background-color: white;
    padding: 24px;
    border-radius: 12px;
background:  #FFF;

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.odTable-flex div{
  display: inline-block;
}
.odTable-flex input{
    padding: 9px 14px;
    color: var(--gray-500, #667085);
width: 100%;
padding-left: 33px;
font-size: 16px;
font-style: normal;
font-weight: 400;
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;
margin-right: 10px ;
width: 146px;

}
.odTable-flex button{
    border-radius: 8px;
background: #F04438;
color: #FCFCFD;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
padding: 10px 16px;
border: none;
width: 108px;

box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.odTableContainer table{
    width: 100%;
    margin-top: 12px;
 
}
.odTableContainer{
    border: 1px solid  #EAECF0;
    border-radius:6px;
border-style: none solid solid solid;
}
.odTableContainer th{
    text-align: start;
  padding: 12px 24px;  
  color:#475467;
font-size: 14px;
font-style: normal;
background:  #F9FAFB;
font-weight: 500;
line-height: 20px;
}
.odTableContainer td{
    padding: 16px 24px;  
    text-align: start;
    border-bottom: 1px solid  #EAECF0;
}
.lastRow td{
    border-bottom: none !important;
  
}
.orderDetContainer{
    border-radius: 12px;
    background: #FFF;
    height: 100%;

    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
        padding:24px  16px ;
}
.orderDetContainer p{
    color:  #344054;
font-size: 14px;
font-weight: 500;
line-height: 20px;
margin-bottom: 8px;
text-align: start;
}
.orderDetContainer .orderDet-title{
    font-size: 16px !important;
margin-bottom: 16px !important;
text-align: start;
color:  #101828;

}
.orderDetContainer span{
    color:#98A2B3;
}
.deliveryContainer{
height: 100%;
border-radius: 12px;
background: #FFF;
text-align: center;

box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
padding: 24px 16px;}
.deliveryContainer p{

    color:  #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
}
.delivery-title{
    color: #000 !important;
    font-size: 16px !important;  
}
.deliveryContainer button{
    border-radius: 8px;
    background: #F73782;
    color: #FCFCFD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    padding: 10px 16px;
    border: none;
  
}
.deliveryContainer span{
    color:#98A2B3;
}
.map{
    height: 350px;
}
.statusModal select{
padding: 10px 14px;

}
.statusModal button{
    border-radius: 8px;
    background: #F73782;
    color: #FCFCFD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    padding: 10px 16px;
    border: none;
    width: 100%;
}
.statusModal .modal-content{
    padding-bottom: 54px !important;
}
.addProduct .modal-content{
    transform: translate(-50%, 2%) !important;
}
.AddAdditional .modal-content{
    transform: translate(-50%, 25%) !important;
}
.filtersMore .select-arrow{
    top: 52% !important;
}
.cashierStatus div{
    display: inline-block;
    margin-left: 10px;
}
input::-webkit-calendar-picker-indicator {
    filter: invert(50%); /* Change the color of the clock icon */
  }
  .editContainer .select-arrow{
      color: rgba(133, 141, 155, 1) 
  }
  .editBranchArea .modal-content{
    transform: translate(-50%, 50%) !important;
}
.editBranchArea .modalBtns{
    width: 50%;
    margin-left: auto;
}
.branchMap .modal-content{
    transform: translate(-50%, 17%) !important;
}

.branchMap .addBody p{
    margin-bottom: 32px;
}
.branchMap .map{
    height: 39vw;
}
.map p{
    text-align: center !important;
}
.qrCode{
    width: 100%;
    height: 28vw;
    padding: 10vw;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}
.addBranch .modal-content{
    transform: translate(-50%, 3%) !important;
   margin-bottom: 6em;
}
.addBranch #uploadImg{
    padding: 3px 14px;
    position: relative;
    margin-bottom: 24px;
    text-align: start;
}
.addBranch #uploadImg p{
    margin-bottom: 0;
    display: inline-block;
}
.addBranch #uploadIcn{
    margin-bottom: 0;
}
.addBranch #uploadImg input{
    margin-bottom: 0;
}
.addBranch .map{
    height:18vw
}
.editMap{
    width: 100%;
    height: 187px;
    border-radius: 12px;

}
.form-switch .form-check-input:checked{
    background-repeat: no-repeat;
    width: 54px !important;
    height: 24px !important;
    background-color: #039855;
    border: none !important;
    box-shadow: inset 0px 0px 0px 0px red ;
    cursor: pointer;

}
.form-switch .form-check-input{
    cursor: pointer;
    background-repeat: no-repeat;
    width: 54px !important;
    height: 24px !important;
    background-color:  #E4E7EC;
    border-color: #E4E7EC;
    box-shadow: inset 0px 0px 0px 0px red ;  
}

.form-switch .form-check-input:focus{
    box-shadow: inset 0px 0px 0px 0px red  !important;  
    border-color: #E4E7EC;

}
.mealStatus{
display: flex;
align-items:center;
justify-content: space-between;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
padding: 19px 21px;
margin-bottom: 29px;
}
.mealStatus input{
    margin-bottom: 0;
}
.mealStatus .statusTitle{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
}
.mealStatus .statusTxt{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 20px;
}
.mealStatus img{
    margin-right: 12px;
}
.changeStatus .modal-content{
    transform: translate(-50%, 5%) !important;
}
.addMdiafProd .modal-content{
    transform: translate(-50%, 10%) !important;
}
.inquireContainer .usersContainer .analysisContainer{
    display: block !important;
}
.inquireContainer .orderContainer{
    height: 77.5%;
    border-radius: 40px;
    padding: 25px;
}

.addVersion  .modal-content{
    transform: translate(-50%, 35%) !important;
}
.tablesModal  .modal-content{
    transform: translate(-50%, 30%) !important;
}
.tablesModal label{
    color:  #344054;
    /* Text sm/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
}
.tablesModal input{
    border-radius: 8px;
border: 1px solid  #D0D5DD;
background:  #FFF;
color:  #667085;
display: block;
font-style: normal;
font-weight: 400;
padding: 10px 14px;
line-height: 24px;
/* Shadow/xs */
width: 170%;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.tablesModal .insert{
    width: 184px;
    border-radius: 8px;
background:  #F50A66;
color: white;

font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
border: none;
    padding: 12px;
}
.tablesModal table{
    width:100%;
}
.tablesModal th,td{
    border-bottom: 1px solid #EAECF0 !important;
text-align: start;
}
.tablesModal th{
    padding: 12px 24px;
    color:  #475467;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
}
.tablesModal td{
    padding:  22px 24px;
    color:  #101828;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
}
.tablesModal .status{
    max-width: 101px;
}
.tablesModal .statusOrange{
    max-width: 69px;
}
.bannerContainer table a{
    color: #C80853 ;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
text-decoration: none;
}