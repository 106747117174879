.loginBackg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    background-color: #E9E9E9;
}
.loginContainer{
    padding:0 2em !important;
    display: flex;
    align-items: start;
    height: 100vh;

}
.formContainer{
    max-width: 450px !important;
    margin: auto;
    padding:  1em;

}
.loginLogo{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15vw;
    width: 16vw;
    max-height: 150px;
    max-width: 150px;

    margin-right: auto;
    margin-bottom: 60px;
}
#welcome{
    font-weight: 600;
    text-align: start;
    font-size: 30px;
    line-height: 38px; 
    color: #101828;
    margin-bottom: 5px;
}
#cc{
    text-align: start;
  color:  #475467;

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
#loginTxt{
    text-align: start;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px; 
    color: #667085;
    
    
}
.emailContainer{
    background: linear-gradient(45deg, #43031C 0%, #C80853 100%);
    min-height: 100vh;
    padding: 35px 0;
    display: flex;
    align-items: center;
}

.passIcn{
    border-radius: 100%;
    border: 10px solid var(--primary-50, #FFDCB9);
    background: var(--primaru-2100, #FFC58B);
display: flex;
align-items: center;
margin: auto;
justify-content: center;
    width: 56px;
    height: 56px;
    margin-bottom: 20px;
  }


.emailForm{
    margin: auto;
    background-color: white;
    border-radius: 12px;
    max-width: 530px;
    padding:48px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
.emailForm .loginLogo{
    margin: auto;
    margin-bottom: 32px;
    max-height: 130px;

}
#forgot{
    color: #101828;

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
 
}
.confirmPass{
    margin-top: 20px !important;
}

.passMin{
    color:  #475467;
  text-align: start;
  margin-top: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;  
}
.bck{
    color: #475467;
font-size: 14px;
font-style: normal;
font-weight: 600;
background-color: transparent;
border: none;
line-height: 20px;
display: flex;
align-items: center;
margin: auto;
transition: transform 0.3s ease-in-out;
}

.bck:hover svg {
  transform: translateX(-6px);
}
.bck svg{
    margin: 0.5em;
    transition: transform 0.3s ease-in-out;

}

#forgotTxt{
    color: #475467;
    /* 16/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
}
.loginForm label, .emailForm label{
text-align: start;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #344054;
margin-top: 1.5em;
margin-bottom: 0.5em;
display: block;
}
.emailForm label{
    margin-top: 32px;

}
.loginForm input, .emailForm input{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #667085;
background: #FFFFFF;
/* Gray/300 */
width: 100%;
padding:8px 10px;

border: 1px solid #D0D5DD;
/* Shadow/xs */

border-radius: 8px;
}

.loginForm input[type="password"]{
    padding:9px 10px !important;

}
input[type="password"]{
    font-size: 20px;
    padding:9px 10px !important;

}
input[type="password"]::placeholder{
    font-size: 16px;


}
.passEmail{
    color:  #475467;
/* Text md/Medium */
font-family: Inter !important;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
margin-bottom:32px ;
}
.passresend{
    color:  #475467;
/* 14/Regular */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}
.passresend span{
    color: #DC6803;
    font-weight: 600;
    cursor: pointer;

}
.forgetDiv{
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forgetDiv label{
  
font-weight: 500;
font-size: 14px;
display: inline-block;
color: #344054;
margin: 0 0.5em;
}
.forgetDiv input{
   width: auto;
}
.forgetDiv button{
background: transparent;
border: none;
font-weight: 600;
font-size: 14px;
margin-bottom: 0;
color: #9c0642c3;
text-decoration:underline;
}
.forgetDiv button:hover{
    color: #ba1457;

}
.forgetDiv input{
    position: relative;
    top: 2px;
    cursor: pointer;
}


#loginBtn{
    font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background: #FF7E00;
  border: none;
  padding:  10px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  margin: 2em 0;
  transition: 0.2s;
  }
  #loginBtn:hover{
    background: rgb(255, 143, 31);

  }
  #reg{
    font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  color: #475467;
  }
  #reg span{
    font-weight: 600;
    color: #ED9434;
    
  }
 
  #languageBtn{
    text-align: end;

  }
  .loginEn #languageBtn svg{
    margin-left: 0;
    margin-right: 5px;

  }
#languageBtn button{
    background: #ED9434;
/* Primary */

padding:  10px;
font-weight: 600;
font-size: 20px;
line-height: 20px;
color: #FFFFFF;
text-transform: uppercase;
border: 1px solid #ED9434;
border-radius: 8px;
}
#languageBtn svg{
    position: relative;
    bottom: 2px;
    margin-left: 5px;
}
.loaderDiv{
    justify-content: center; 
    align-items: center;
    display: flex;}
.loginForm span{
    font-size: 14px;
    color: brown;
    text-align: start;
    display: block;
}
.invalid{
    border: 2px solid brown !important;
}
#cc a{
    cursor: pointer;
    color: #FF7E00;
}
@media screen and (max-width: 575px){
    #languageBtn button{
        font-size: 16px;
padding: 7px;

    }
    .loginBackg{
        display: none;
    }
    .loginLogo{
        height: 42vw;
        width: 28vw;
    }

    #welcome{
        font-size: 24px;
        margin-bottom: 7px;

    }
    #loginTxt{
        font-size: 16px;

    }
    .loginForm input{

    }
}