.externalNav{
    display: flex;
    justify-content: space-between;
}
.externalNav p{
    color: #AFB1B6;
    /* 20/Semibold */
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;

}
.externalNav .activeTab{
    color:  #C80853;
border-bottom: 2px solid #C80853;
}
.externalNav p:hover{
    color:  #C80853;
    border-bottom: 2px solid #C80853;
}
.externalData{
    border-radius: 8px;
background: #F9FAFB;
box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
padding: 34px 14px;
margin-top: 48px;
text-align: start;
}
.externalContainer{
    height: 100%;
}
.externalData p{
    /* 20/Semibold */
    font-size: 20px;
 /* 150% */
    color: var(--gray-500, #667085);

    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.externalData span{
    color: var(--gray-600, #475467);
    /* 20/Semibold */
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
}
.delegateDiv{
    padding: 24px 16px;
    border-radius: 12px;
border: 1px solid #00B871;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
}
.delegateTitle{
    color: var(--gray-800, #1D2939) !important;
margin-bottom: 16px;
font-size: 20px;
font-style: normal;
font-weight: 600 !important;
line-height: 30px; 
}

.delegateTitle span{
    color: #00B871 !important;

}
.unVeri span{
    color: #BE1717 !important;

}
.unVeri{
    border: 1px solid #CE5151 !important;

}
.delegateDiv div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.delegateDiv div p{
margin-bottom: 8px;
    color: var(--gray-400, #98A2B3);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}
.delegateDiv .delegateName{
    color: var(--gray-700, #344054);

}
.documents p{
    color: var(--secondary-400, #F50A66);
    /* 30/Medium */
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 8px;
}
.documents .documentImg{
    border-radius: 12px;
    height: 126px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.noDocs{
    text-align: center;
}
.noDocs div{
    margin: auto;
    border-radius: 12px;
    height: 388px;
    width: 523px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.noDocs p{
    color: var(--gray-700, #344054);

font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
}
.editAgent input, .editAgent  .select-container select,.editAgent .mutli-select{

    margin-bottom: 28px !important;
}
 .select-dropdown label, .select-dropdown input{
     margin-bottom: 0 !important;
 }

 .AddModal .mutli-select{
    margin-bottom: 24px !important;

 }
 .addBody  .mutli-select p{
     font-size: 16px !important;
    color: #667085 !important;
    margin-bottom: 0 !important;
    line-height: 29px !important;
    font-weight: 400 !important;

 }
 .supervisor{
     background-color: #FEE9F1;
     padding: 10px 17px;
     margin-bottom: 24px;
     display: flex;
     align-items: center;
     width: 50%;
     margin-right: auto;
 }
 .supervisor input{
     margin: 0 !important;
     width: min-content;
     
 }
 .supervisor label{
     margin-bottom: 0 !important;
     margin-left: 5px;
 }
.chocies{
    padding: 0 9px;
    border-radius: 6px;
background: var(--secondary-25, #FEE9F1);
margin-right: 12px;
display: inline-block;
}
.closeGray path{
    stroke:rgba(152, 162, 179, 1)
      }
      .addBody .chocies p{ color: var(--gray-700, #344054) !important;
  text-align: center;
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;}
  .adminTable button{
      background-color: transparent;
      border-radius: 4px;
      width: 41px;
      height: 41px;

      border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .deleteBtn{
      margin-left: 20px;
  }
  .deleteBtn svg{
color:rgba(235, 77, 43, 1)
  }
  .DelModal p{
    color: var(--neutrals-90, #2B2F32);
    text-align: center;
  margin: 26px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .DelModal .modal-content{
      width: 495px !important;
      transform: translate(-50%, 50%) !important;
      padding: 62px 32px !important;
  }
  .DelModal .cancel{
    width: 156px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
font-style: normal;
font-weight: 700;

    justify-content: center;
    background: rgba(216, 216, 216, 0.50);
    color: var(--neutrals-4, #AFB1B6);
border: none;
margin-right: 16px;
  }
  .DelModal .confirm{
    width: 156px;
    height: 40px;
    font-size: 16px;
font-style: normal;
font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--semantic-red, #D0021B); 
}
.DelModal .modalBtns{
    justify-content: center;
}
.closeModal{
    position: absolute;
    background-color: transparent;
    border: none;
    right: 17px;
    top: 16px;
}
.errSpan{
    font-size: 13px;
    text-align: start;
    color: brown;
    display: block;

}
.invalid + .errSpan{
    margin-bottom: 24px;
}